<template>
  <div class="block-slider-hero">
    <swiper
      v-if="data.slides && data.slides.length > 0"
      :direction="'horizontal'"
      :pagination="true"
      :grab-cursor="true"
      :modules="[Virtual, Pagination, Autoplay]"
      :resistance-ratio="0"
      :autoplay="autoPlayOptions"
      class="block-slider-hero__swiper"
    >
      <swiper-slide
        v-for="(slide, index) in data.slides"
        :key="slide._uid + Math.random()"
      >
        <BlockSliderHeroSlide
          :data="slide"
          :disable-lazy="index === 0"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
// import Swiper core and required modules
const {
    Virtual,
    Pagination,
    Autoplay,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const autoPlayOptions = computed(() => {
    if (props.data.autoplay) {
        return {
            delay: props.data.autoplay_delay || 12000,
            disableOnInteraction: props.data.disable_on_interaction || false,
        };
    }

    return false;
});
</script>

<style lang="scss">
.block-slider-hero {
    width: 100%;
    height: 100%;

    .swiper {
        width: 100%;
        height: 100%;

        .swiper-pagination {
            padding: 20px 51px;
            background-color: $C_GREEN_BRIGHT;
            border-top-right-radius: 18px;

            @include mobile {
                padding: 10px 19px;
            }
        }

        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom, .swiper-pagination-fraction {
            bottom: 0;
            width: auto;
        }

        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            margin: 0 5px;
            background-color: $C_PRIMARY;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background-color: $C_GREEN_DARK;
        }
    }
}
</style>
